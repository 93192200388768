require.config({
    baseUrl : (( typeof JSConst === 'object') ? JSConst.config.absRefPrefix : '/') + 'src/js'
});
require(['default/config'], function() {
    // @formatter:off
    require([
        'jquery',
        'mmc.libs.namespace',
        'mmc.libs.TrackingHelper',
        'ofi',
        'partials/views/rehabil/LocationMap',
        'partials/views/rehabil/Accordion',
        'mmc.libs.documentDomain',
        //'mmc.libs.iFrameResizer'
    ], function(
        $,
        namespace,
        TrackingHelper,
        ofi,
        LocationMap,
        Accordion
    ) {
    // @formatter:on

        var trackingHelper;

        /*
         * init trackinghelper
         */
        (function() {
            var pageName;
            try {
                pageName = parent.dataLayer[0].pageNameVirtual;
            } catch(e) {
                pageName = '';
            }
            var trackingHelperConfig = {
                clean : true,

                pageName : ((JSConst.config.tracking.gtm.pageName.length > 0) ? JSConst.config.tracking.gtm.pageName : pageName),
                trackingCategory : 'Campaigns',
                trackingAction : JSConst.config.tracking.gtm.action,

                // To deactivate tracking for a specific tracking-engine set the function : null
                trackingMethods : {
                    //trackGAJS : null,
                    trackAnalyticsJS : null,
                    trackOmniture : null,
                    trackGenericGTM : null
                }
            };

            objectFitImages();

            new LocationMap('.map svg', '.special-list');
            new Accordion('.accordion-wrap');

            $('.nav-burger').on('click', function(e) {
                $(e.currentTarget).parent('.main-nav').toggleClass('is-open');
            })

            trackingHelper = new TrackingHelper(trackingHelperConfig);
        })();
    });
});

define("default/master", function(){});

