define('partials/views/rehabil/Accordion',['jquery'], function($) {
    function Accordion(selector) {
        this.SELECTOR = selector;
        this._init();
    }

    Accordion.prototype._init = function () {
        $(this.SELECTOR).find('.acc-header').on('click', function(e) {
            $(e.currentTarget).closest('.accordion-item').toggleClass('is-open');
            $(e.currentTarget).next('.acc-content').slideToggle();
        });
    };

    return Accordion;
});

