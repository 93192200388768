define('partials/views/rehabil/LocationMap',['jquery'], function($) {
    function LocationMap(mapselector, tableselector) {
        this.MAP_SELECTOR = mapselector;
        this.TABLE_SELECTOR = tableselector;
        this.MARKER_FLAG = 'setmarker';

        this._init();
    }

    LocationMap.prototype._init = function() {
        $(this.MAP_SELECTOR).find('.state').on('click', this._selectState.bind(this));

        if (window.location.search.toLowerCase().indexOf(this.MARKER_FLAG) > -1) {
            this._enableMarkerHelp();
        }

        $('button.reset').on('click', function(e) {
            e.preventDefault();
            this._resetState();
        }.bind(this));

        $('.standorte-select').on('change', function(e) {
            var state = $(e.currentTarget).val();

            if (state === 'all') {
                this._updateTable(state, true);
                this._updateMarkers(state, true);
                return;
            }

            this._updateTable(state, false);
            this._updateMarkers(state, false);
        }.bind(this));

        this._addTooltip();
    };

    LocationMap.prototype._resetState = function(e) {
        $(this.MAP_SELECTOR).find('.state').removeClass('active');
        this._updateTable(null, true);
        this._updateMarkers(null, true);
    };

    LocationMap.prototype._selectState = function (e) {
        $(this.MAP_SELECTOR).find('.state').removeClass('active');
        $(e.currentTarget).addClass('active');
        $('button.reset').fadeIn();

        var state = $(e.currentTarget).attr('id');
        this._updateTable(state, false);
        this._updateMarkers(state, false);
    };

    LocationMap.prototype._updateTable = function (state, reset) {
        if (reset) {
            $(this.TABLE_SELECTOR).find('.list-item').removeClass('inactive').fadeIn();
            return;
        }
        $(this.TABLE_SELECTOR).find('.list-item').each(function(index, element) {
            if ($(element).hasClass(state)) {
                $(element).removeClass('inactive').fadeIn();
            } else {
                $(element).addClass('inactive');
            }
        });
    };

    LocationMap.prototype._updateMarkers = function (state, reset) {
        if (reset) {
            $(this.MAP_SELECTOR).find('.PIN-white').addClass('inactive');
            return;
        }
        $(this.MAP_SELECTOR).find('.PIN-white').each(function(index, element) {
            if ($(element).hasClass(state)) {
                $(element).removeClass('inactive');
            } else {
                $(element).addClass('inactive');
            }
        });
    };

    LocationMap.prototype._enableMarkerHelp = function () {
        console.info('Marker help enabled!');

        $('.debugging__line').show();
        $('.map').find('.txt-box').hide();

        var map = $(this.MAP_SELECTOR)[0];
        var pt = map.createSVGPoint();

        $(this.MAP_SELECTOR).on('mousemove', function(e) {
            pt.x = e.clientX;
            pt.y = e.clientY;
            var svgGlobal = pt.matrixTransform(map.getScreenCTM().inverse());
            $('.debugging__option--left').text('top: ' + svgGlobal.y + 'px');
            $('.debugging__option--top').text('left: ' + svgGlobal.x + 'px');
        });
    };

    LocationMap.prototype._addTooltip = function() {
        $('.PIN-white').on('mouseenter', function(e) {
            $(this.MAP_SELECTOR).closest('.map').find('.tooltip').text($(e.currentTarget).data('title')).css({
                left: e.clientX - $('.tooltip').width()/2,
                top: e.clientY - ($('.map').offset().top - $(window).scrollTop() + 50)
            }).stop().fadeIn();
        }.bind(this)).on('mouseleave', function() {
            $(this.MAP_SELECTOR).closest('.map').find('.tooltip').stop().fadeOut();
        }.bind(this));
    };

    return LocationMap;
});

