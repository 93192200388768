require.config({
    paths: {
        urlArgs: 'noCache=' + (new Date()).getTime(),

        // libs
        backbone: '../../node_modules/backbone/backbone',
        fastclick: '../../node_modules/fastclick/lib/fastclick',
        jquery: '../../node_modules/jquery/dist/jquery',
        'jquery-private': '../../node_modules/jquery-private/dist/jquery-private',
        'jquery-cycle-2': '../../node_modules/jquery-cycle-2/src/jquery.cycle.all',
        'jquery-form': '../../node_modules/jquery-form/dist/jquery.form.min',
        underscore: '../../node_modules/underscore/underscore',
        'ofi': '../../node_modules/object-fit-images/dist/ofi',

        // mmc libs
        'mmc.libs.booleanExpressionParser': '../../node_modules/booleanExpressionParser/dist/booleanExpressionParser',
        'mmc.libs.documentDomain': '../../node_modules/documentDomain/dist/documentDomain',
        'mmc.libs.iFrameResizer': '../../node_modules/iFrameResizer/dist/iFrameResizer',
        'mmc.libs.log': '../../node_modules/log/dist/log',
        'mmc.libs.jquery.mmcFileUploader': '../../node_modules/mmcFileUploader/dist/mmcFileUploader',
        'mmc.libs.jquery.mmcFormDefaults': '../../node_modules/mmcFormDefaults/dist/mmcFormDefaults',
        'mmc.libs.namespace': '../../node_modules/namespace/dist/namespace.min',

        'mmc.libs.FormValidation': '../../node_modules/FormValidation/dist/FormValidation',
        'mmc.libs.Get': '../../node_modules/Get/dist/Get',
        'mmc.libs.ShareIconsModule': '../../node_modules/ShareModule/dist/ShareModule',
        'mmc.libs.TrackingHelper': '../../node_modules/TrackingHelper/dist/TrackingHelper'
    },

    shim: {
        'jquery-cycle-2': {
            deps: ['jquery']
        }
    },

    map: {
        // '*' means all modules will get 'jquery-private'
        // for their 'jquery' dependency.
        //'*': {'jquery': 'jquery-private'},

        // 'jquery-private' wants the real jQuery module
        // though. If this line was not here, there would
        // be an unresolvable cyclic dependency.
        //'jquery-private': {'jquery': 'jquery'}
    }

});

define("default/config", function(){});

